<template>
    <div>
      <div>
        <!-- <div class="input-group mb-3">
          <input type="text" class="form-control" placeholder="Search by title" v-model="title" />
          <div class="input-group-append">
            <button class="btn btn-outline-secondary" type="button" @click="searchTitle">
              Search
            </button>
          </div>
        </div> -->
  
        <div class="form-group">
          <label for="exampleFormControlSelect1"
            ><h5 style="width: 200px">
              <i class="bi bi-person-plus"></i> 마을 출석 현황
            </h5></label
          >
          <select
            class="form-control"
            id="holiday"
            name="holiday"
            v-model="holiday"
            @change="getTotalAttend()"
          >
            <option id="" value="00">날짜를 선택하세요.</option>         
            <option
              v-for="daylist in dayLists"
              :key="daylist.dday"
              v-bind:value="daylist.dday"
              :id="daylist.dday"
              :selected="daylist.dday == daylist.cday"
            >
              {{ daylist.dday }}
            </option>
          </select>          
        </div>
      </div>
  
   <!--  재적 인원 -->
     <div style="height: 20px;"></div>
  <!--  출석 인원 -->

  <div class="card">
    <h5 class="card-header">출석인원</h5>
    <div class="card-body">
      <h5 class="card-title"></h5>
      <p class="card-text">
        <table class="table table-bordered" style="font-size: 14px;">
            <!-- <thead>
                <tr style="text-align: center; background-color:#007bff45;">
                    <th scope="col" rowspan="2"><p>학년</p></th>
                    <th scope="col" colspan="3">제적</th>
                    <th scope="col" colspan="3">출석</th>            
                    <th scope="col" rowspan="2">비율</th>
                  </tr>                
              <tr style="text-align: center; background-color:#007bff1a;">  
                <th scope="col">남</th>              
                <th scope="col">여</th>
                <th scope="col">합계</th>
                <th scope="col">남</th>
                <th scope="col">여</th>
                <th scope="col">합계</th>                
              </tr>
            </thead> -->
            <thead style="font-size: 12px;">
              <tr style="text-align: center; background-color:#007bff45;">
                  <th scope="col" style="width: 20%; padding-top: 3px;    padding-left: 3px;    padding-right: 3px;    padding-bottom: 3px;">셀</th>
                  <th scope="col" style="width: 20%; padding-top: 3px;    padding-left: 3px;    padding-right: 3px;    padding-bottom: 3px;">제적</th>
                  <th scope="col" style="width: 20%; padding-top: 3px;    padding-left: 3px;    padding-right: 3px;    padding-bottom: 3px;">예배 출석</th>            
                  <th scope="col" style="width: 20%; padding-top: 3px;    padding-left: 3px;    padding-right: 3px;    padding-bottom: 3px;">셀 출석</th>            
                  <th scope="col" style="width: 20%; padding-top: 3px;    padding-left: 3px;    padding-right: 3px;    padding-bottom: 3px;">비율(셀)</th>
                </tr>                
          </thead>            
            <tbody>

              <tr v-for="item in enrollList" :key="item.attenddate"  style="text-align: center;">
                <td style="font-size: 12px;">{{ item.name}}</td>
                <td style="font-size: 12px;">{{ item.total_students}}</td>
                <td style="font-size: 12px;">{{ item.attend_count}}</td>
                <td style="font-size: 12px;">{{ item.cellattend_count}}</td>
                <td v-if="getNum(Math.ceil(( parseInt(item.cellattend_count) / parseInt(item.total_students)) * 100)) > 60 " style="font-size: 12px;"><span style="color:green;">{{ getNum(Math.ceil(( parseInt(item.cellattend_count) / parseInt(item.total_students)) * 100)) }}% </span></td>   
                <td v-else style="font-size: 12px;"><span style="color:red;">{{ getNum(Math.ceil(( parseInt(item.cellattend_count) / parseInt(item.total_students)) * 100)) }}% </span></td>              
              </tr>
            </tbody>
            <tfoot>
              <tr style="text-align: center; background-color: #f8f9fa; font-weight: bold;">
                <td>합계</td>
                <td>{{ sumTotalStudents }}</td>
                <td>{{ sumAttendCount }}</td>
                <td>{{ sumCellAttendCount }}</td>
                <td v-if="getNum(Math.ceil(( parseInt(sumCellAttendCount) / parseInt(sumTotalStudents)) * 100)) > 60 " style="font-size: 12px;"><span style="color:green;">{{ getNum(Math.ceil(( parseInt(item.cellattend_count) / parseInt(item.total_students)) * 100)) }}% </span></td>   
                <td v-else style="font-size: 12px;"><span style="color:red;">{{ getNum(Math.ceil(( parseInt(sumCellAttendCount) / parseInt(sumTotalStudents)) * 100)) }}% </span></td>              
              </tr>
            </tfoot>            
          </table>       
      </p>
    </div>
  </div>
    </div>
</template>      

<script>
    import manageService from "../services/manageService";
   
    //import $ from "jquery";
    
    export default {
      name: "list",
      component: {},
      data() {
        return {
            enrollList: {},  
            attendLists : {},          
            pageTotalCount: 0,
            page: 15,
            holiday: "00",
            dayLists: {},     
            currentDept: "",            
            id: this.$store.state.id,
            grade: this.$store.state.grade,
            phone: this.$store.state.phone,
            name: this.$store.state.name,
            teacherid: this.$store.state.teacherid,
            role: this.$store.state.role,
            token: this.$store.state.token,  
            classid: this.$store.state.classid,
            gradefullname : "",
            totalsum : [],
            sumTotalStudents: 0,
            sumAttendCount: 0,
            sumCellAttendCount: 0,            

        };
      },

      methods: {
        getNum(val) {
            if (isNaN(val)) {
              return 0;
            }
            return val;
        },
        getDayList(){      //날짜 조회
            manageService
            .getDaysList()
            .then((response) => {

            this.dayLists = response.data.slice(0, this.page);   
          // console.log("11",this.dayLists);         
            })
            .catch((e) => {
            console.log(e);
            });
            },
        getTotalAttend(){     //전체 출결 통계 

          if(this.holiday === "00") return;

            const data = {
                attenddate: this.holiday,
                classid: this.classid,
                };
  
            manageService
            .gettotalattend(data)
            .then((response) => {

            this.enrollList = response.data;   

            this.sumTotalStudents = this.enrollList.reduce((sum, item) => sum + parseInt(item.total_students || 0), 0);
            this.sumAttendCount = this.enrollList.reduce((sum, item) => sum + parseInt(item.attend_count || 0), 0);
            this.sumCellAttendCount = this.enrollList.reduce((sum, item) => sum + parseInt(item.cellattend_count || 0), 0);

            })
            .catch((e) => {
            console.log(e);
            });
        },
    },   
      mounted() {
        //this.getTotalAttend(); //전체 출석
        this. getDayList(); //날짜 조회
      },
    };
    </script>
    
    <style scoped>
    .list {
      text-align: left;
      max-width: 750px;
      margin: auto;
    }
    .done {
      background: oldlace;
    }
    .card-body {
      padding: 0.5rem;
    }
    </style>
    