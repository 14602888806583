import { createStore } from 'vuex';
import CryptoJS from 'crypto-js';

const secretKey = 'donganyouthmanna2024!!'; // 비밀 키 설정

const store = createStore({
  state: {
    id: null,
    grade: null,
    phone: null,
    name: null,
    teacherid: null,
    studentid: null,
    role: null,
    token: null,
    classid: null,
    classname: null, // classid에 따른 classname 추가
    isLogin: false,
  },
  mutations: {
    login(state, userData) {
      state.id = userData.id;
      state.grade = userData.grade;
      state.phone = userData.phone;
      state.name = userData.name;
      state.teacherid = userData.teacherid;
      state.studentid = userData.studentid;
      state.role = userData.role;
      state.token = userData.token;
      state.classid = userData.classid;
      state.classname = {
        '1': '채움마을',
        '2': '이룸마을',
        '3': '세움마을',
        '11': '새가족외',
      }[userData.classid] || '기타';
      state.isLogin = true;
    },
    logout(state) {
      state.id = null;
      state.grade = null;
      state.phone = null;
      state.name = null;
      state.teacherid = null;
      state.studentid = null;
      state.role = null;
      state.token = null;
      state.classid = null;
      state.classname = null; // 로그아웃 시 classname도 초기화
      state.isLogin = false;
    },
    setLoginState(state, userData) {
      state.id = userData.id;
      state.grade = userData.grade;
      state.phone = userData.phone;
      state.name = userData.name;
      state.teacherid = userData.teacherid;
      state.studentid = userData.studentid;
      state.role = userData.role;
      state.token = userData.token;
      state.classid = userData.classid;
      state.classname = {
        '1': '채움마을',
        '2': '이룸마을',
        '3': '세움마을',
        '11': '새가족외',
      }[userData.classid] || '기타';
      state.isLogin = true;
    }
  },
  actions: {
    login({ commit }, userData) {
      const encryptedUserData = CryptoJS.AES.encrypt(
        JSON.stringify(userData), 
        secretKey
      ).toString();

      localStorage.setItem('userData', encryptedUserData); 
      localStorage.setItem('tokenExpiry', Date.now() + (60 * 60 * 1000)); // 1시간 유효
      commit('login', userData);
    },
    logout({ commit }) {
      localStorage.removeItem('userData');
      localStorage.removeItem('tokenExpiry');
      commit('logout');
    },
    checkLoginState({ commit }) {
      const encryptedUserData = localStorage.getItem('userData');
      const tokenExpiry = localStorage.getItem('tokenExpiry');

      if (encryptedUserData && tokenExpiry && Date.now() < tokenExpiry) {
        const bytes = CryptoJS.AES.decrypt(encryptedUserData, secretKey);
        const decryptedUserData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8)); // 복호화된 데이터

        commit('setLoginState', decryptedUserData);
      } else {
        console.log('User is not logged in or session expired');
        localStorage.removeItem('userData');
        localStorage.removeItem('tokenExpiry');
        commit('logout');
      }
    }
  },
  getters: {
    classname(state) {
      return state.classname; // getter로 classname 반환
    }
  }
});

export default store;
