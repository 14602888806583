<template>
  <div>
    <div>
      <!-- <div class="input-group mb-3">
          <input type="text" class="form-control" placeholder="Search by title" v-model="title" />
          <div class="input-group-append">
            <button class="btn btn-outline-secondary" type="button" @click="searchTitle">
              Search
            </button>
          </div>
        </div> -->

      <div class="form-group">
        <label for="exampleFormControlSelect1"
          ><h5 style="width: 200px">
            <i class="bi bi-person-plus"></i> 셀원 편성
          </h5></label
        >
        <!-- <span
            ><button
              type="button"
              class="btn btn-primary btn-sm"
              @click="AddMember"
            >
              신규
            </button>
          </span> -->
        <!-- @change="onChange(this)" -->
     
      </div>
    </div>
      <!-- Name search input and button -->
      <div class="input-group mb-3">
        <input 
          type="text" 
          class="form-control" 
          placeholder="이름 검색" 
          v-model="searchName" 
        />
        <div class="input-group-append">
          <button 
            class="btn btn-outline-secondary" 
            type="button" 
            @click="MemberGetListAll">
            검색
          </button>
        </div>
      </div>
    <div>
      <select
        class="form-control"
        id="gradegroup"
        name="gradegroup"
        v-model="gradegroup"
        @change="MemberGetListAll()"
        v-show="this.role < 3"
      >
        <option id="" value="0">전체</option>
        <option
          v-for="gradelist in gradegrouplist"
          :key="gradelist.no"
          :value="gradelist.no"
          :id="gradelist.no"
        >
          {{ gradelist.name }}  {{ gradelist.rolename }}
        </option>
      </select>
    </div>
    <p></p>

    <div>
      <ul class="list-group" v-if="memberLists.length > 0">
        <li
          class="list-group-item align-items-center justify-content-between "
          v-for="memberList in memberLists"
          :key="memberList.no"
          style="padding-left: 10px; padding-right: 10px"
        >
          <div class="row">
            <div class="col-7">
              <b>
                <div class="row">
                  <div class="col-sm-7">
                    <span class="align-middle"
                      >                    {{ memberList.name }}({{ getLastFourDigits(memberList.tel) }})</span
                    >
                  </div>
                </div>
              </b>
            </div>
            <!-- 미등록 상태 -->
            <div class="col-5" style="text-align: right;" v-if="memberList.studentid === '' || memberList.studentid === null">
              <span style="padding-left: 0px">
                <button
                  type="button"
                  class="btn btn-success btn-sm"
                  @click="applymemberset(memberList)"
                >
                  미등록
                </button>
              </span>                            
            </div>

            <!-- 이미 등록 -->
            <div class="col-5" style="text-align: right;" v-else>
              <span style="padding-left: 0px" v-if="memberList.teacherid === '0' || memberList.teacherid === null || memberList.teacherid === '' ">
                <button
                  type="button"
                  class="btn btn-primary btn-sm"
                  @click="openReaderModal(memberList, 0)"
                >
                  미배정
                </button>
              </span>
              <span style="padding-left: 0px" v-else>
                <button
                  type="button"
                  class="btn btn-secondary btn-sm"
                  @click="openReaderModal(memberList, 1)"
                >
                  배정
                </button>
              </span>                      
            </div>
            <!-- END -->
          </div>
        </li>
      </ul>

      <ul class="list-group" v-else>
        <div class="row" style="width: 100%; text-align: center">
          <div class="col-12">조회된 자료가 없습니다.</div>
        </div>
      </ul>
      <!-- <button class="m-3 btn btn-sm btn-danger" @click="removeAllTutorials">
            Remove All
          </button> -->
    </div>

    <div
      class="modal fade"
      id="reportModal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="reportModalLabel"
      aria-hidden="true"
    >
    </div>

    <!-- Include the modal component -->
    <ReaderListModal v-if="showModal" @select-reader="AssignAuthoSet" />

      <!-- Confirmation Modal for Assignment Cancellation -->
      <div class="modal fade" id="confirmationModal" tabindex="-1" role="dialog" aria-labelledby="confirmationModalLabel" aria-hidden="true">
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="confirmationModalLabel">배정 취소 확인</h5>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              배정 취소하시겠습니까?
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-primary" @click="confirmCancelAssignment">예</button>
              <button type="button" class="btn btn-secondary" data-dismiss="modal">아니오</button>              
            </div>
          </div>
        </div>
      </div>

      <!-- Your existing code -->

  </div>
</template>

<script>
import manageService from "../services/manageService";
import ReaderListModal from "./ReaderListModal.vue";
import $ from "jquery";

export default {
  name: "list",
  components: {
    ReaderListModal,
  },
  data() {
    return {
      pageTotalCount: 0,
      page: 15,
      holiday: "00",
      currentDept: "",
      memberLists: {},
      id: this.$store.state.id,
      grade: this.$store.state.grade,
      phone: this.$store.state.phone,
      name: this.$store.state.name,
      teacherid: this.$store.state.teacherid,
      role: this.$store.state.role,
      token: this.$store.state.token,
      classid: this.$store.state.classid,
      form: {
        studentid: "",
        teacherid: "",
        no: "",
        attenddate: "",
        pray: "",
        name: "",
        sex: "",
        classid: "",
        groupid: "",
        grade: "",
      },
      isNew: false,
      firstselect: "selected",
      gradegrouplist: {},  //셀리더 불러오기
      gradegroup: "0",
      showModal: false,
      showConfirmationModal: false,
      selectedMember: null,
      searchName: "", // Added for search functionality
    };
  },
  computed: {
    photourl() {
      return (url) => {
        return "./upload/photo/" + url;
      };
    },
  },
  methods: {
    
    //최초 등록을 우선 해야 한다.
    applymemberset(member){ 
      this.selectedMember = member;

      const data = {
        classid: this.selectedMember.dept,
        birthday: this.selectedMember.birthday, 
        phone: this.selectedMember.tel,
        assignyn: 'X',
        rederyn: 'N',
        name: this.selectedMember.name,
        teacherid: 0,  // Assuming reader.id is the ID of the selected reader
        studentid: this.selectedMember.studentid,
        grade: this.selectedMember.grade,
        sector: '1',  // Assuming sector is always '1' for this action
        holiday: this.holiday,
        groupid: this.selectedMember.groupid,
        sex: this.selectedMember.sex,
        photo: this.selectedMember.photo,
      };

      manageService
      .AssignMemberSetting(data)
        .then(() => {  
            this.$toast.show("등록되었습니다.", {
              type: "success",
              position: "top-right",
            });                    
          this.MemberGetListAll();
        })
        .catch((e) => {
          console.log(e);
        });      
    },
    getLastFourDigits(tel) {
      return tel ? tel.slice(-4) : '';
    },    
    MemberGetListAll() {
      const data = {
        attenddate: this.holiday,
        grade: this.gradegroup,
        teacherid: this.teacherid,
        role: this.role,
        classid: this.classid,
        gradegroup: this.gradegroup,          
        searchName: this.searchName, // Include searchName in the request
      };

      manageService
        .getCellMemberList(data)
        .then((response) => {
          this.memberLists = this.searchName
            ? response.data.filter((member) =>
                member.name.includes(this.searchName)
              )
            : response.data;
        })
        .catch((e) => {
          console.log(e);
        });
    },
    //리더 드롭다운리스트 조회
    ReaderGetListAll() {
      const ndata = {
        classid: this.classid,
        grade: this.gradegroup,
        role: this.role,
      };

      manageService
        .getCellReaderList(ndata)
        .then((response) => {
          //console.log(response.data);
          this.gradegrouplist = response.data;
        })
        .catch((e) => {
          console.log(e);
        });
    },
    getHtmlConvert(str) {
      const replacer = new RegExp("\\n", "g");
      return str.replace(replacer, "<br />");
    },
 openReaderModal(member, condi) {
      this.selectedMember = member;
      if (condi === 0) {
        this.showModal = true;
        setTimeout(() => {
          $('#readerModal').modal('show');
        }, 100);
      } else {
        this.showConfirmationModal = true;
        setTimeout(() => {
          $('#confirmationModal').modal('show');
        }, 100);
      }
    },
    async  AssignAuthoSet(reader) {

      // if (reader < 1) {
      //   alert('셀 리더를 먼저 선택하세요.');
      //   return;
      // }

      var assignyn = ""; 
      var rederyn = "";  

      if (reader == 0) {
        assignyn = "N";
      } else {
        assignyn = "Y";
      }

      const data = {
        classid: this.selectedMember.dept,
        birthday: this.selectedMember.birthday, 
        phone: this.selectedMember.tel,
        assignyn: assignyn,
        rederyn: rederyn,
        name: this.selectedMember.name,
        teacherid: reader,  // Assuming reader.id is the ID of the selected reader
        studentid: this.selectedMember.studentid,
        grade: this.selectedMember.grade,
        sector: '1',  // Assuming sector is always '1' for this action
        holiday: this.holiday,
        groupid: this.selectedMember.groupid,
        sex: this.selectedMember.sex,
        photo: this.selectedMember.photo,
      };

      await manageService
      .AssignMemberSetting(data)
        .then(() => {

          if (reader == 0) {
            this.$toast.show("셀 배정이 취소되었습니다.", {
              type: "success",
              position: "top-right",
            });
          } else {
            this.$toast.show("셀이 배정되었습니다.", {
              type: "success",
              position: "top-right",
            });            
          }
          this.MemberGetListAll();
        })
        .catch((e) => {
          console.log(e);
        });

      this.showModal = false;
    },
    confirmCancelAssignment() {
      this.AssignAuthoSet(0);
      $('#confirmationModal').modal('hide');
    },
    async AssignReaderSet(item, sector) {
      var assignyn = "";
      var rederyn = "";

      if (sector == "2") {
        rederyn = "N";
      } else {
        rederyn = "Y";
      }          

      const data = {
        classid: item.dept,
        birthday: item.birthday, 
        phone: item.tel,
        assignyn: assignyn,
        rederyn: rederyn,
        name: item.name,
        studentid: item.studentid,
        grade: item.grade,
        sector: sector,
        holiday: this.holiday,
        teacherid: item.teacherid,
        groupid: item.groupid,
        sex: item.sex,
        photo: item.photo,
      };

      await manageService
      .AssignReaderSetting(data)
        .then(() => {

          if (sector == "2") {
            this.$toast.show("리더를 취소했습니다.", {
              type: "success",
              position: "top-right",
            });
          } else {
            this.$toast.show("리더로 등록했습니다.", {
              type: "success",
              position: "top-right",
            });            
          }
          this.ReaderGetListAll()
          this.MemberGetListAll();
        })
        .catch((e) => {
          console.log(e);
        });
    },
  },
  created() {
    this.ReaderGetListAll();
    this.MemberGetListAll();
  },
};
</script>

<style>
/* Add your styles here */
</style>