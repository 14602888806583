<template>
  <div>
    <div>
      <ul class="list-group">
        <li
          class="list-group-item active"
          aria-current="true"
          style="
            text-align: center;
            font-size: 20px;
            background-color: #b3b7bd;
            border-color: #8c9097;
            color: black;
            font-weight: 600;
          "
        >
          {{ member.membername }}
        </li>
        <li class="list-group-item">마을: {{ member.dept }}</li>
        <li class="list-group-item">연락처: {{ member.tel }}</li>
      </ul>
    </div>

    <div
      style="height: 2px;    background: #007bff;    margin-top: 10px;    margin-bottom: 10px;}"
    ></div>
    <div>
      <form name="regFormData" v-on:submit="submitForm" id="regFormData">
        <input
          type="hidden"
          name="memberno"
          id="memberno"
          v-model="member.memberno"
        />
        <input
          type="hidden"
          name="membername"
          id="membername"
          v-model="member.membername"
        />
        <input type="hidden" name="pltel" id="pltel" v-model="plphoneNumber" />
        <input type="hidden" name="dept" id="dept" v-model="member.dept" />
        <input type="hidden" name="tel" id="tel" v-model="member.tel" />

        <!-- 감염 발생 구분 -->
        <div class="form-group">
          <label for="exampleFormControlSelect1">1. 구분</label>
          <select class="form-control" id="sector" name="sector" required>
            <option
              v-for="sectorCategory in sectorCategorys"
              :key="sectorCategory.id"
              v-bind:value="sectorCategory.codeno"
              :id="sectorCategory.codenno"
            >
              {{ sectorCategory.codename }}
            </option>
          </select>
        </div>

        <div class="form-group">
          <label for="exampleFormControlInput1">2. 발생 경로</label>
          <input
            type="text"
            class="form-control"
            id="path"
            name="path"
            v-model="path"
            placeholder="발생 경로를 기입하세요."
          />
        </div>

        <div class="form-group">
          <label for="exampleFormControlSelect1">3. 백신 접종 차 수</label>
          <select class="form-control" id="vaccine" name="vaccine" required>
            <option value="00">선택하세요.</option>
            <option value="20">미접종</option>
            <option value="21">1차</option>
            <option value="22">2차</option>
            <option value="23">3차</option>
          </select>
        </div>
        <div class="form-group">
          <label for="exampleFormControlTextarea1">4. PL 의견</label>
          <textarea
            class="form-control"
            id="pl"
            name="pl"
            rows="5"
            v-model="pl"
            required
            placeholder="대상자 증상 및 비말접촉자,주변 및 근무 영향 등 상세하게 입력해주세요. 행내에서 발생하여 영향을 받게 된 경우, 영향을 제공한 사람을 등록해주세요."
          ></textarea>
        </div>
        <div class="form-group">
          <label for="exampleFormControlInput1"
            >5. 주변 근무자 등록 (제외 가능합니다.)</label
          >
          <ul class="list-group">
            <li
              class="list-group-item"
              v-for="(mapitem, index) in mapSet"
              :key="mapitem.id"
            >
              <div class="container">
                <div class="row">
                  <div class="col-6" v-if="mapitem.display == 'X'">없음</div>
                  <div class="col-6" v-else>
                    {{ mapitem.display }}
                  </div>
                  <div class="col-3">
                    <button
                      type="button"
                      class="btn btn-primary btn-sm"
                      v-if="mapitem.remark != null"
                      @click="SendingToPL(mapitem, member.membername)"
                    >
                      SMS(PL)
                    </button>
                  </div>
                  <div class="col-3">
                    <button
                      type="button"
                      class="btn btn-secondary btn-sm"
                      v-if="mapitem.display !== 'X'"
                      @click="DeleteRow(index)"
                    >
                      제외
                    </button>
                  </div>
                </div>
              </div>
            </li>
          </ul>

          <!-- <div class="row">
            <div class="col-9">
              <input
                type="text"
                class="form-control"
                id="spreader"
                name="spreader"
                v-model="spreader"
                placeholder=""
                readonly
              />
            </div>
            <div class="col-3">
              <button
                type="button"
                class="btn btn-primary"
                data-toggle="modal"
                data-target="#searchModal"
                data-whatever="@mdo"
              >
                찾기
              </button>
            </div>
          </div> -->
        </div>
        <!-- <div class="form-group">
          <label for="exampleFormControlTextarea1">5. PMO 의견</label>
          <textarea
            class="form-control"
            id="pmo"
            name="pmo"
            rows="3"
          ></textarea>
        </div> -->
        <div>
          <button type="sumit" class="btn btn-primary btn-lg btn-block">
            등록
          </button>
        </div>
      </form>
    </div>
    <!-- modal -->

    <div
      class="modal fade"
      id="searchModal"
      tabindex="-1"
      aria-labelledby="searchModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="searchModalLabel">
              이름으로 검색해주세요.
            </h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div>
              <div class="row" style="padding-bottom: 10px">
                <div class="col-3" style="text-align: right; line-height: 35px">
                  이름:
                </div>
                <div class="col-6">
                  <input
                    type="text"
                    class="form-control"
                    id="searchName"
                    name="searchName"
                    v-model="member.membername"
                    v-on:keyup.enter="memberSearch()"
                  />
                </div>
                <div class="col-3">
                  <input
                    class="btn btn-primary"
                    type="button"
                    value="검색"
                    @click="memberSearch()"
                  />
                </div>
              </div>
            </div>
            <div>
              <table class="table">
                <thead></thead>
                <tbody>
                  <tr
                    v-for="(SearchMemberList, index) in SearchMemberLists"
                    :key="SearchMemberList.id"
                    style="vertical-align: middle"
                  >
                    <th scope="row">{{ index + 1 }}</th>
                    <td>{{ SearchMemberList.membername }}</td>
                    <td>{{ SearchMemberList.dept }}</td>
                    <td>
                      <button
                        type="button"
                        class="btn btn-success btn-sm"
                        @click="selectName(SearchMemberList)"
                      >
                        선택
                      </button>
                      <button
                        type="button"
                        class="btn btn-warning btn-sm"
                        @click="SearchSeatMap(SearchMemberList)"
                        style="margin-left: 10px"
                      >
                        좌석
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>

              <div v-html="mapDraw"></div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              data-dismiss="modal"
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- modal -->
  </div>
</template>

<script>
import manageService from "../services/manageService";
import codeService from "../services/codeService";
import memberListService from "../services/memberListService";
import seatmapService from "../services/seatmapService";
import $ from "jquery";

export default {
  name: "trgtRegister",
  component: {},
  data() {
    return {
      member: {},
      pl: "",
      sectorCategorys: [],
      plphoneNumber: localStorage.getItem("tel"),
      path: "",
      spreader: "",
      SearchMemberLists: [],
      people: {},
      mapSet: {},
      mapDraw: "",
    };
  },
  created() {
    this.member = this.$route.params;
    this.SearchSeatMap(this.member.memberno);
  },
  computed: {

  },
  methods: {
    //PL에게 문자 발송함
    SendingToPL(mapinfo,targetName){

        let dis = mapinfo.display.replace(/\n|\r|\s*/g, "");
        let msg = dis + "님이 "+targetName+"님의 주변근무자로 등록되었습니다.";

        let formdata = new FormData();
        formdata.append("sender", "01053731570"); // text data
        formdata.append("receiver",mapinfo.remark); // binary data
        formdata.append("msg",msg);
        formdata.append("msg_type","SMS");
        formdata.append("title","주변근무자 등록 안내");
        formdata.append("destination","");

          manageService
          .sendToPLMsg(formdata)
          .then(() => {
            this.message = "";
            this.$toast.show("발송되었습니다.", {
              type: "warning",
              position: "top-right",
            });
          })
          .catch((e) => {
            this.$toast.show("발송되지 않았습니다.", {
              type: "success",
              position: "top-right",
            });
            console.log(e);
          });

    },    
    // 주변근무자 row 삭제
    DeleteRow(row) {
      this.mapSet.splice(row, 1);
      //console.log(row);
    },
    //좌석 찾아오기
    SearchSeatMap(item) {
      var xx = 0;
      var yy = 0;
      var rc = "";
      //let display = "";
      let mapData = {};

      const data = {
        memberno: item,
      };

      seatmapService
        .getList(data)
        .then((response) => {
          this.people = response.data[0];
          rc = response.data[0].rc;
          //console.log("people == ", this.people);

          xx = this.people.xx;
          yy = this.people.yy;

          if (rc == "R") {
            let div = xx % 2;
            let calc = 0;
            if (div == 0) calc = xx + 1;
            else calc = xx - 1;
            let order = "" + yy + "," + (yy - 1) + "," + (yy + 1);
            mapData = {
              xx2: calc,
              yy2: yy,
              xx4: xx,
              yy4: yy - 1,
              xx6: xx,
              yy6: yy + 1,
              rc: rc,
              ord: order,
            };
          } else {
            let div = yy % 2;
            let calc = 0;
            if (div == 0) calc = yy - 1;
            else calc = yy + 1;
            let order = "" + xx + "," + (xx - 1) + "," + (xx + 1);

            mapData = {
              xx2: xx,
              yy2: calc,
              xx4: xx - 1,
              yy4: yy,
              xx6: xx + 1,
              yy6: yy,
              rc: rc,
              ord: order,
            };
          }
          //console.log(mapData);
          this.getmapList(mapData);
        })
        .catch((e) => {
          console.log(e);
        });
    },
    getmapList(mapData) {
      let map1,
        map2,
        map3 = "";

      seatmapService
        .getMapList(mapData)
        .then((response) => {
          this.mapSet = response.data;
          //console.log("mapSet == ", this.mapSet);

          for (var i in this.mapSet) {
            if (
              mapData.xx2 == this.mapSet[i].xx &&
              mapData.yy2 == this.mapSet[i].yy
            )
              map1 = this.mapSet[i].display;
            if (
              mapData.xx4 == this.mapSet[i].xx &&
              mapData.yy4 == this.mapSet[i].yy
            )
              map2 = this.mapSet[i].display;
            if (
              mapData.xx6 == this.mapSet[i].xx &&
              mapData.yy6 == this.mapSet[i].yy
            )
              map3 = this.mapSet[i].display;
          }
          //console.log(map1, map2+"."+map3);

          this.mapDraw =
            '<div class="container" style="font-size: 14px;">  <div class="row" style="height: 31px; font-size: 11px;">    <div class="col">  </div>  <div class="col" style="background: aqua;">  ' +
            map1 +
            ' </div>  <div class="col"> </div> </div> <div class="row" style="height: 31px; font-size: 11px;">  <div class="col" style="background: aqua;"> ' +
            map2 +
            ' </div> <div class="col" style="background: darkorange;"> ' +
            this.people.display +
            '</div>  <div class="col" style="background: aqua;"> ' +
            map3 +
            " </div>  </div> </div>";

          // if(this.people.rc == "C"){
          //   this.mapDraw = '<div class="container" style="font-size: 14px;">  <div class="row" style="height: 31px;">    <div class="col">  </div>  <div class="col" style="background: aqua;">  '+this.mapSet[0].display+' </div>  <div class="col"> </div> </div> <div class="row">  <div class="col" style="background: aqua;"> '+this.mapSet[2].display+' </div> <div class="col" style="background: darkorange;"> '+this.people.display+'</div>  <div class="col" style="background: aqua;"> '+this.mapSet[1].display+' </div>  </div> </div>';
          // } else {
          //   this.mapDraw = '<div class="container" style="font-size: 14px;">  <div class="row" style="height: 31px;">    <div class="col">  </div>  <div class="col" style="background: aqua;">  '+this.mapSet[1].display+' </div>  <div class="col"> </div> </div> <div class="row">  <div class="col" style="background: aqua;"> '+this.mapSet[0].display+' </div> <div class="col" style="background: darkorange;"> '+this.people.display+'</div>  <div class="col" style="background: aqua;"> '+this.mapSet[2].display+' </div>  </div> </div>';
          // }

          // <table style="width:100%; text-align: center;"><tr><td style="width: 33%;"></td><td style="width: 33%;background-color: aqua;">'+this.mapSet[1].display+'</td><td style="width: 33%;"></td></tr><tr><td style="background-color: aqua;">'+this.mapSet[0].display+'</td><td style="background-color: coral;">'+this.people.display+'</td><td style="background-color: aqua;">'+this.mapSet[2].display+'</td></tr></table>';
        })
        .catch((e) => {
          console.log(e);
        });
    },
    // 멤버 선택
    selectName(item) {
      this.spreader = item.membername + "_" + item.memberno;
      $("#searchModal").modal("hide");
    },
    //멤버 찾기
    memberSearch() {
      var search = document.getElementById("searchName").value;

      if (search.length < 2) {
        this.$toast.show("두 글자 이상 입력해주세요.", {
          type: "warning",
          position: "top-right",
        });
        return false;
      }
      const data = {
        membername: search.trim(),
      };

      memberListService
        .getSearchName(data)
        .then((response) => {
          this.SearchMemberLists = response.data;
          //console.log(this.SearchMemberLists);
        })
        .catch((e) => {
          console.log(e);
        });
    },
    submitForm(e) {
      e.preventDefault();

      var object = {};
      var fform = document.getElementById("regFormData");
      var form = new FormData(fform);
      form.forEach(function (value, key) {
        object[key] = value;
        //console.log(key, value);
      });

      //주변근무자 등록 
      let arr_spreader = "";
      this.mapSet.forEach(function (el) {
        if (el.display !== "X") {
          if(arr_spreader !== "") arr_spreader += ",";
          arr_spreader = arr_spreader + el.display;
        }
      });
      object["spreader"] = arr_spreader;
      //console.log("el ==", arr_spreader);
      var jsondata = JSON.stringify(object);

      if (JSON.parse(jsondata).sector == "00") {
        this.$toast.show("구분을 선택해주세요.", {
          type: "success",
          position: "top-right",
        });
        return;
      }

      if (JSON.parse(jsondata).vaccine == "00") {
        this.$toast.show("백신 접종 차 수를 선택해주세요.", {
          type: "success",
          position: "top-right",
        });
        return;
      }
      manageService
        .insert(jsondata)
        .then(() => {
          this.message = "";
          this.$toast.show("저장되었습니다.", {
            type: "warning",
            position: "top-right",
          });
          this.$router.push("/memberList");
        })
        .catch((e) => {
          this.$toast.show("다시 확인해주세요.", {
            type: "success",
            position: "top-right",
          });
          console.log(e);
        });
      //console.log(JSON.parse(jsondata).sector);
    },

    updateTutorial(tutorial) {
      //e.preventDefault();
      // let myForm = document.getElementById("checkForm")
      // let form = new FormData(myForm);

      const data = {
        id: tutorial.id,
        words: tutorial.words,
        pray: tutorial.pray,
        opinon: tutorial.opinon,
      };
      // need to convert it before using not with XMLHttpRequest
      // for (let [key, val] of form.entries()) {
      //   Object.assign(data, { [key]: val })
      //  // console.log({ [key]: val })
      // }

      if (tutorial.words || tutorial.pray) {
        manageService
          .update(tutorial.id, data)
          .then(() => {
            this.message = "";
            this.$toast.show("저장되었습니다.", {
              type: "warning",
              position: "top-right",
            });
          })
          .catch((e) => {
            this.$toast.show("다시 확인해주세요.", {
              type: "success",
              position: "top-right",
            });
            console.log(e);
          });
      } else {
        this.$toast.show("입력 내용이 없습니다.", {
          type: "success",
          position: "top-right",
        });
      }
    },
    getSectorCategory() {
      const data = {
        grp: "100", //감염발생구분
      };

      codeService
        .getList(data)
        .then((response) => {
          this.sectorCategorys = response.data;
        })
        .catch((e) => {
          console.log(e);
        });
    },
  },
  mounted() {
    this.getSectorCategory();
  },
};
</script>
