<template>
  <div>
    <div class="card" style="width: 100%">
      <div class="card-body">
        <h5 class="card-title">회원탈퇴</h5>

        <p class="card-text">▶ 탈퇴 시 유의사항</p>
        <p class="card-text">
          탈퇴 하실 경우에는 모든 자료가 삭제되며, 같은 아이디로 재가입이
          불가하오니 유의해주시기 바랍니다.
        </p>
      </div>
      <div>
        <button
          type="button"
          class="btn btn-primary btn-lg btn-block"
          @click="goOut()"
        >
          탈퇴 요청
        </button>
      </div>
    </div>
    <p></p>

    <!-- Modal -->
    <div
      class="modal fade"
      id="exampleModal"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">
              {{ items.subject }}
            </h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body" v-html="items.contents"></div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              data-dismiss="modal"
            >
              닫기
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import manageService from "../services/manageService";
  import dayjs from "dayjs";
  import $ from "jquery";

  export default {
    component: {
      dayjs,
    },
    data() {
      return {
        items: [],
        nlists: {},
        slists: {},
        id: this.$store.state.id,
        grade: this.$store.state.grade,
        phone: this.$store.state.phone,
        name: this.$store.state.name,
        teacherid: this.$store.state.teacherid,
        role: this.$store.state.role,
        token: this.$store.state.token,
        classid: this.$store.state.classid,
      };
    },
    computed: {
      formatDate() {
        return (v) => {
          return dayjs(v).format("YY.MM.DD");
        };
      },
    },
    methods: {
      detailView(item) {
        this.items = item;
        //    data-toggle="modal" data-target="#exampleModal"

        $("#exampleModal").modal();
      },
      getData() {
        const data = {
          classid: this.classid,
        };

        manageService
          .getboardDashboard(data)
          .then((response) => {
            this.nlists = response.data.notice;
            this.slists = response.data.study;
          })
          .catch((e) => {
            console.log(e);
          });
      },
      goOut() {
        const data = {
          id: this.id,
        };

        manageService
          .setGoOut(data)
          .then(() => {

            this.$toast.show("정상적으로 탈퇴 처리 되었습니다.", {
              type: "warning",
              position: "top-right",
            });
            this.$store.commit("logout");
            localStorage.clear();
            localStorage.removeItem("id");
            localStorage.removeItem("grade");
            localStorage.removeItem("phone");
            localStorage.removeItem("name");
            localStorage.removeItem("teacherid");
            localStorage.removeItem("role");
            localStorage.removeItem("classid");

            this.$router.push({ path: "/login" });
          })
          .catch((e) => {
            console.log(e);
          });
      },
    },
    created() {
      this.getData();
      //console.log("getisLogin",this.$store.state);
    },
  };
</script>
<style scoped>
  .table-dark {
    color: #000;
    background-color: #007bff80;
  }
  .table td,
  .table th {
    padding: 0.5rem;
  }
</style>
