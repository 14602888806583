<template>
  <div>
    <div>
      <!-- <div class="input-group mb-3">
        <input type="text" class="form-control" placeholder="Search by title" v-model="title" />
        <div class="input-group-append">
          <button class="btn btn-outline-secondary" type="button" @click="searchTitle">
            Search
          </button>
        </div>
      </div> -->

      <div class="form-group">
        <label for="exampleFormControlSelect1"
          ><h5 style="width: 200px">
            <i class="bi bi-person-plus"></i> 대상자 등록
          </h5></label
        ><span
          ><button
            type="button"
            class="btn btn-primary btn-sm"
            @click="AddMember"
          >
            신규
          </button>
        </span>
        <!-- @change="onChange(this)" -->
        <select
          class="form-control"
          id="dept"
          name="dept"
          v-model="dept"
          @change="changeDept(this)"
        >
          <option
            v-for="deptCategory in deptCategorys"
            :key="deptCategory.id"
            v-bind:value="deptCategory.dept"
            :id="deptCategory.dept"
            :selected="deptCategory.dept == currentDept"
          >
            {{ deptCategory.dept }}
          </option>
        </select>
      </div>
    </div>

    <div>
      <ul class="list-group">
        <li
          class="list-group-item d-flex justify-content-between align-items-center"
          v-for="memberList in memberLists"
          :key="memberList.id"
          style="padding-left: 0.5rem; padding-right: 0.1rem"
        >
          <div class="row" style="width: 100%">
            <div class="col-5">
              {{ memberList.dept }}
            </div>
            <div class="col-3">
              <b>
                <div>
                  <span v-if="grade > 1" @click="modifyMember(memberList)">{{
                    memberList.membername
                  }}</span>
                  <span v-else>{{ memberList.membername }}</span>
                </div>
              </b>
            </div>
            <div class="col-4" style="text-align: right">
              <button
                type="button"
                class="btn btn-warning"
                @click="trgtRegister(memberList)"
              >
                등록
              </button>
            </div>
          </div>

          <span></span> <span></span>
          <!-- <span class="badge badge-primary badge-pill">14</span> -->
          <span> </span>
        </li>
      </ul>
      <!-- <button class="m-3 btn btn-sm btn-danger" @click="removeAllTutorials">
          Remove All
        </button> -->
    </div>
    <div>
      <div class="row" v-if="!currentDept">
        <div class="col-12">
          <div class="list-group" id="list-tab" role="tablist">
            <a
              class="list-group-item list-group-item-action"
              id="list-home-list"
              data-toggle="list"
              @click="onClickDept(deptCategory)"
              role="tab"
              aria-controls="home"
              v-for="deptCategory in deptCategorys"
              :key="deptCategory.id"
              v-bind:value="deptCategory.dept"
              >{{ deptCategory.dept }}
            </a>
          </div>
        </div>
      </div>
    </div>

    <div
      class="modal fade"
      id="memberAddModal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="memberAddModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5
              class="modal-title"
              id="memberAddModalLabel"
              style="color: blue"
            >
              <span id="plpmo"></span>
              <div v-if="isNew">팀원 신규 등록</div>
              <div v-else>팀원 수정</div>
            </h5>
          </div>
          <div class="modal-body">
            <div class="form-group">
              <form
                id="addMemberform"
                name="addMemberform"
                v-on:submit="addMemberForm"
              >
                <div class="form-group mx-sm-3 mb-2">
                  <input
                    type="text"
                    class="form-control"
                    id="formmemberno"
                    v-model="form.memberno"
                    name="formmemberno"
                    autocomplete="off"
                    placeholder="행번 입력, 없을 시O9999999로 입력, O는 대문자"
                    :readonly="!isNew"
                  />
                </div>
                <div class="form-group mx-sm-3 mb-2">
                  <input
                    type="text"
                    class="form-control"
                    id="formmembername"
                    name="formmembername"
                    v-model="form.membername"
                    autocomplete="off"
                    placeholder="이름을 입력하세요."
                  />
                </div>
                <div class="form-group mx-sm-3 mb-2">
                  <select
                    class="form-control"
                    id="formdept"
                    name="formdept"
                    v-model="form.dept"
                  >
                    <option
                      v-for="deptCategory in deptCategorys"
                      :key="deptCategory.id"
                      v-bind:value="deptCategory.dept"
                      :id="deptCategory.dept"
                    >
                      {{ deptCategory.dept }}
                    </option>
                  </select>
                </div>
                <div class="form-group mx-sm-3 mb-2">
                  <input
                    type="text"
                    class="form-control"
                    id="formtel"
                    name="formtel"
                    v-model="form.tel"
                    autocomplete="off"
                    placeholder="휴대폰 번호를 입력하세요.(숫자만)"
                  />
                </div>
                <div class="form-group mx-sm-3 mb-2">
                  <select
                    class="custom-select"
                    id="formsector"
                    name="formsector"
                    v-model="form.sector"
                  >
                    <option value="재직">재직</option>
                    <option value="퇴직">퇴직</option>
                  </select>
                </div>
                <div class="modal-footer">
                  <button type="submit" class="btn btn-primary" v-if="isNew">
                    등록
                  </button>
                  <button type="submit" class="btn btn-primary" v-else>
                    수정
                  </button>
                  <button
                    type="button"
                    class="btn btn-secondary"
                    data-dismiss="modal"
                  >
                    Close
                  </button>
                  <span>※행번은 주변근무자를 검색하고, 휴대폰으로는 PMO에서 가이드를 발송합니다.</span>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>


    <!-- 공지사항 모달 -->
    <!-- Modal -->
    <div
      class="modal fade"
      id="noticeModal"
      tabindex="-1"
      aria-labelledby="noticeModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="noticeModalLabel">업데이트 알림</h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              @click="modalClose(this)"
            ></button>
          </div>
          <div class="modal-body"> <p v-html="noticeLists"></p></div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-success"
              data-bs-dismiss="modal"
              @click="modelConfirm(this.userInfo)"
            >
              확인
            </button>            
            <button
              type="button"
              class="btn btn-secondary"
              data-bs-dismiss="modal"
              @click="modalClose(this)"
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- 공지사항 모달 -->
  </div>
</template>

<script>
import memberListService from "../services/memberListService";
import loginService from "../services/LoginService";
import noticeService from "../services/noticeService";

import $ from "jquery";

export default {
  name: "list",
  component: {},
  data() {
    return {
      deptCategorys: {},
      tutorials: [],
      currentTutorial: null,
      currentIndex: -1,
      submitted: false,
      deptCategory: "",
      currentDept: localStorage.getItem("dept") != null ? localStorage.getItem("dept") : this.userInfo.dept,
      memberLists: [],
      sector: "",
      dept: localStorage.getItem("dept") != null ? localStorage.getItem("dept") : this.userInfo.dept,
      grade:localStorage.getItem("grade") != null ? localStorage.getItem("grade") : this.userInfo.grade,
      form: {
        memberno: "",
        membername: "",
        dept: "",
        tel: "",
        sector: "",
        memo: "",
      },
      isNew: true,
      noticeLists : [],
      noticeListtemp : [],
    };
  },
  created() {
    this.initGetMemberList(this.currentDept);
  },
  computed: {
  },
  methods: {
    //개행문자 인식
    getHtmlConvert(str) {
      const replacer = new RegExp("\\n", "g");
      return str.replace(replacer, "<br />");
    },    
    //공지사항 모달
    NoticeOpen(){
        const data ={
          membername : '%'+this.userInfo.membername+'%'
        }
        noticeService
          .getList(data)
          .then((res) => {            
            console.log(res.data);
            this.noticeListtemp = res.data[0];
            if(res.data[0].contents !== null) this.noticeLists  = this.getHtmlConvert(res.data[0].contents);
            //this.initGetMemberList(this.currentDept);
            $("#noticeModal").modal("show");
          })
          .catch((e) => {
            console.log(e);
          });
    },
    //확인 시 업데이트
    modelConfirm(item){

      if(this.noticeLists) {
          const data ={
            memo : this.noticeListtemp.memo+","+item.membername,
            noticeid :  this.noticeListtemp.id
          }

//console.log("noticeList=", this.noticeListtemp)
    console.log(data);

            noticeService
              .setReadDone(data)
              .then((res) => {            
                console.log(res.data);
                //this.initGetMemberList(this.currentDept);
                $("#noticeModal").modal("hide");
              })
              .catch((e) => {
                console.log(e);
              });
      }

    },
    //공지사항 모달
    modalClose() {
      $("#noticeModal").modal("hide");
      
    },
    //팀원 수정
    modifyMember(item) {
      this.isNew = false;
      this.form.memberno = item.memberno;
      this.form.membername = item.membername;
      this.form.dept = item.dept;
      this.form.tel = item.tel;
      this.form.sector = item.sector;

      $("#memberAddModal").modal("show");
    },
    //팀원 등록
    addMemberForm(e) {
      e.preventDefault();
      var object = {};
      var fform = document.getElementById("addMemberform");
      var form = new FormData(fform);
      form.forEach(function (value, key) {
        object[key] = value;
        console.log(key, value);
      });
      var jsondata = JSON.stringify(object);

      if (
        JSON.parse(jsondata).formmemberno.length < 5 ||
        JSON.parse(jsondata).formmembername.length < 2 ||
        JSON.parse(jsondata).formtel.length < 10
      ) {
        this.$toast.show("등록된 내용이 없거나 내용이 부족합니다.", {
          type: "warning",
          position: "top-right",
        });
        return;
      }

      if (
        JSON.parse(jsondata).formsector == "00" ||
        JSON.parse(jsondata).formdept == "00"
      ) {
        this.$toast.show("마을 또는 재직 구분을 선택해주세요.", {
          type: "warning",
          position: "top-right",
        });
        return;
      }
      console.log(this.isNew);
      if (this.isNew == true) {
        //신규 모드
        memberListService
          .register(jsondata)
          .then(() => {
            // console.log(response.data);
            this.$toast.show("정상적으로 등록되었습니다.", {
              type: "success",
              position: "top-right",
            });
            $("#memberAddModal").modal("hide");
          })
          .catch((e) => {
            console.log(e);
            this.$toast.show("등록되지 않았습니다.", {
              type: "warning",
              position: "top-right",
            });
          });
      } else {
        //수정 보드
        memberListService
          .update(jsondata)
          .then(() => {
            // console.log(response.data);
            this.$toast.show("정상적으로 수정되었습니다.", {
              type: "success",
              position: "top-right",
            });
            $("#memberAddModal").modal("hide");
            this.initGetMemberList(this.currentDept);
          })
          .catch((e) => {
            console.log(e);
            this.$toast.show("수정되지 않았습니다.", {
              type: "warning",
              position: "top-right",
            });
          });
      }
    },
    //멤버 추가 팝업
    AddMember() {
      this.isNew = true;
      this.form.memberno = "";
      this.form.membername = "";
      this.form.dept = "00";
      this.form.tel = "";
      this.form.sector = "00";

      $("#memberAddModal").modal("show");
    },
    changeDept() {
      var opt = $("#dept option:selected").text();

      this.currentDept = opt;
      const data = {
        dept: opt,
      };
      memberListService
        .getList(data)
        .then((response) => {
          this.memberLists = response.data;
        })
        .catch((e) => {
          console.log(e);
        });
    },

    // 리스트 선택 시
    onClickDept(opt) {
      //  var opt = $("#dept option:selected").text();
      this.currentDept = opt.dept;
      const data = {
        dept: opt.dept,
      };
      memberListService
        .getList(data)
        .then((response) => {
          this.memberLists = response.data;
        })
        .catch((e) => {
          console.log(e);
        });
    },

    //최초 자동으로 리스트 가져오기
    initGetMemberList(opt) {
      if (opt == "undefined" || opt == null) {
        //값이 없으면 pass
      } else {
        this.currentDept = opt;
        const data = {
          dept: opt,
        };
        memberListService
          .getList(data)
          .then((response) => {
            this.memberLists = response.data;
          })
          .catch((e) => {
            console.log(e);
          });
      }
    },

    trgtRegister(memberList) {
      this.$router.push({
        name: "trgtRegister",
        params: {
          memberno: memberList.memberno,
          membername: memberList.membername,
          dept: memberList.dept,
          tel: memberList.tel,
        },
      });
    },
    updateTutorial(tutorial) {
      const data = {
        id: tutorial.id,
        words: tutorial.words,
        pray: tutorial.pray,
        opinon: tutorial.opinon,
      };

      if (tutorial.words || tutorial.pray) {
        memberListService
          .update(tutorial.id, data)
          .then(() => {

            this.message = "";
            this.$toast.show("저장되었습니다.", {
              type: "warning",
              position: "top-right",
            });
          })
          .catch((e) => {
            this.$toast.show("다시 확인해주세요.", {
              type: "success",
              position: "top-right",
            });
            console.log(e);
          });
      } else {
        this.$toast.show("입력 내용이 없습니다.", {
          type: "success",
          position: "top-right",
        });
      }
    },

    retrieveDeptCategory() {
      // const data = {farmcode: this.farmcode}
      loginService
        .getDept()
        .then((response) => {
          this.deptCategorys = response.data;
        })
        .catch((e) => {
          console.log(e);
        });
    },

    // refreshList() {
    //   this.retrieveTutorials();
    //   this.currentTutorial = null;
    //   this.currentIndex = -1;
    // },

    // setActiveTutorial(tutorial, index) {
    //   this.currentTutorial = tutorial;
    //   this.currentIndex = tutorial ? index : -1;
    // },

    // removeAllTutorials() {
    //   Farm2022DataService.deleteAll()
    //     .then(response => {
    //       console.log(response.data);
    //       this.refreshList();
    //     })
    //     .catch(e => {
    //       console.log(e);
    //     });
    // },

    // searchTitle() {
    //   Farm2022DataService.findByTitle(this.title)
    //     .then(response => {
    //       this.tutorials = response.data;
    //       this.setActiveTutorial(null);
    //       console.log(response.data);
    //     })
    //     .catch(e => {
    //       console.log(e);
    //     });
    // }
  },
  mounted() {
    this.retrieveDeptCategory();     
    this.NoticeOpen(); //공지사항  
  },
};
</script>

<style>
.list {
  text-align: left;
  max-width: 750px;
  margin: auto;
}
.done {
  background: oldlace;
}
</style>
